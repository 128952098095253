import React, { useState } from 'react';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../enums/labels.enum';
import { Button } from '../../components/button';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  consultarServicioMutation,
  consultarServicioMutationVariables
} from '../../__api__/consultarServicioMutation';
import { useMe } from '../../hooks/use-me';
import { Exceptions } from '../../enums/exceptions.enum';
import { productsByCategoryId_productsByCategoryId } from '../../__api__/productsByCategoryId';
import { fetchServicioForm, fetchServicioForm_getServiciosBasicosForm_fields } from '../../__api__/fetchServicioForm';

interface IBuyProductProps {
  onOk: (consultarServicioMutation_consultarServicio_package) => void;
  product: productsByCategoryId_productsByCategoryId | null;
}


const FORM_FOR_SERVICIOS = gql`
  query fetchServicioForm($input: ServicioGetFormInput!) {
    getServiciosBasicosForm(input: $input) {
      fields
      {
        name
        id
        index
        referencia
        options
        {
          name
          id
        }
      }
      key
    }
  }
`;

const CONSULTAR_SERVICE = gql`
  mutation consultarServicioMutation($input: ServicioConsultarInput!) {
    consultarServicio(input: $input)
    {
      package
      {
        consultaKey
        nombres
        direccion
        documentoID
        valorSinComision
        valorComision
        admiteBono
      }
      code
      mensaje
    }
  }
`;

export const BuyService: React.FC<IBuyProductProps> = ({ onOk, product }) => {
  const { data } = useMe();
  const { data: formData, loading: formLoading } = useQuery<fetchServicioForm>(
    FORM_FOR_SERVICIOS,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          id: product?.subid,
        },
      },
    },
  );

  const [errorCON, seterrorCON] = useState<string | null>(null);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onCompleted = async (data: consultarServicioMutation) => {
    const { consultarServicio: consulta } = data;
    if (consulta) {
      if (consulta.code != "200") {
        seterrorCON(consulta.mensaje);
        return;
      }
      onOk(consulta.package);
    }
  };
  const [consultarServicioMutation, { loading, error }] = useMutation<
    consultarServicioMutation,
    consultarServicioMutationVariables
  >(CONSULTAR_SERVICE, {
    onCompleted,
  });

  const onSubmit = async () => {
    if (!loading) {
      seterrorCON(null);
      try {
        if (product?.subid == undefined || formData?.getServiciosBasicosForm.key == undefined) {
          seterrorCON("Producto Invalido");
          return;
        }
        const { ...input } = getValues();
        await consultarServicioMutation({
          variables: {
            input: {
              userId: data?.me.id || '',
              productId: product?.id || '',
              id: product?.subid,
              key: formData?.getServiciosBasicosForm.key,
              values: input
            },
          },
        });
      } catch (error: any) {
        seterrorCON(error.toString());
      }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
          </h3>
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto
          </h4>
        </div>
        {
          !formLoading && (
            <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                {formData?.getServiciosBasicosForm.fields !== null && formData?.getServiciosBasicosForm.fields.map((field: fetchServicioForm_getServiciosBasicosForm_fields) => {
                  if (field.options === null) {
                    field.options = [];
                  }
                  return field.options.length === 0 ? (
                    <div key={field.id}>
                      <label htmlFor={field.id} className="label">
                        {field.name}
                      </label>
                      <div className="mt-1">
                        <input
                          ref={register({
                            required: FormErrorMessages.REQUIRED_FIELD,
                            minLength: 1,
                            maxLength: 30
                          })}
                          name={field.id}
                          minLength={1}
                          maxLength={30}
                          className="input"
                          type="text"
                          style={{ fontSize: '25px', padding: '10px', fontWeight: 'bold'}} // Aumenta el tamaño del texto y el espacio interno y añade negrita
                        />
                        {errors[field.id]?.message && (
                          <FormError errorMessage={errors[field.id]?.message} />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div key={field.id}>
                      <label htmlFor={field.id} className="label">
                        {field.name}
                      </label>
                      <div className="mt-1">
                        <select
                          name={field.id}
                          ref={register({ required: true })}
                          className="input"
                        >
                          <option value="">{Labels.SELECTED_ONE}</option>
                          {field.options.map((family: any) => (
                            <option key={family.id} value={family.id}>
                              {family.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  );
                })}
                <Button
                  canClick={isValid}
                  loading={loading}
                  actionText={Labels.CONSULTAR}
                />
                {error && (
                  <FormError errorMessage={Exceptions[error.message as any]} />
                )}
              </form>
              {errorCON && (
                <FormError errorMessage={errorCON} />
              )}
            </div>
          )
        }

      </div>
    </div>
  );
};
